import {ajax} from 'rxjs/ajax';
import {map} from 'rxjs/operators';

function url(path) {
  return `https://th-covid-19-api.pcvdev.com${[path]}`;
  // return `http://localhost:8002${[path]}`;
}

function httpGet(urlPath) {
  return ajax({
    url: url(urlPath),
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

/**
 * @returns {import('rxjs').Observable}
 */
export function getProvices() {
  return httpGet('/groupby/province').pipe(
    map((ajaxResponse) => ajaxResponse.response)
  );
}

/**
 * @returns {import('rxjs').Observable}
 */
export function getCases() {
  return httpGet('/cases').pipe(
    map(ajaxResponse => ajaxResponse.response)
  );
}

