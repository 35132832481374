import {get, isNil, takeRight, isString, range} from 'lodash';
import moment from 'moment';
import config from '../Config';

/**
 * @param {Covid.Case[]} cases
 * @param {string} fieldName
 * @returns {Object.<any, Covid.Group>}
 */
export function groupBy(cases, fieldName) {
  if (isNil(cases)) {
    return {};
  }

  /** @type {Object.<any, Covid.Group>} */
  const groups = {};

  cases.forEach(cs => {
    const value = get(cs, fieldName);
    if (!(value in groups)) {
      groups[value] = {
        fieldName,
        fieldValue: value,
        cases: [],
        count: 0,
      };
    }
    /** @type {Covid.Group} */
    const group = groups[value];
    group.cases.push(cs);
    group.count++;
  });

  return groups;
}

/**
 * @param {number|string} [age]
 * @param {number} [yearsWidth]
 * @returns {string|undefined}
 */
export function ageToAgeRange(age, yearsWidth) {
  if (isNil(age)) {
    return undefined;
  }
  if (isNil(yearsWidth)) {
    yearsWidth = 10;
  }
  if (isString(age)) {
    age = parseInt(age);
  }
  const minAge = yearsWidth * Math.floor(age / yearsWidth);
  const maxAge = minAge + yearsWidth - 1;
  return `${minAge}-${maxAge}`;
}

/**
 * @param {number} yearsWidth 
 * @param {number} [maxAge]
 */
export function allAgeRanges(yearsWidth, maxAge) {
  if (isNil(maxAge)) {
    maxAge = 110;
  }
  return range(0, maxAge, yearsWidth).map(
    start => `${start}-${start + yearsWidth - 1}`,
  );
}

/**
 * @param {string} dateString String in format 'YYYY-MM-DD' e.g. '2020-01-07'
 * @returns {moment.Moment}
 */
export function dateStringToMoment(dateString) {
  return moment(dateString).utc(true);
}

/**
 * @param {moment.Moment} momentDate
 * @returns {string}
 */
export function momentToDateString(momentDate) {
  return momentDate.format('YYYY-MM-DD');
}

/**
 * @returns {moment.Moment}
 */
export function todayDate() {
  return moment().utc(true);
}

/**
 * @returns {string}
 */
export function todayDateString() {
  return momentToDateString(todayDate());
}

/**
 * @param {Covid.Case[]} cases
 * @param {number} [numDays]
 * @returns {Covid.DateSeriesItem[]} cases 
 */
export function getCountDateSeries(cases, numDays) {
  const dateGroups = groupBy(cases, 'announceDate');

  if (isNil(numDays)) {
    numDays = config.num_display_days;
  }
  const startDateString = momentToDateString(
    todayDate().add(-(numDays + 1), 'days'),
  );
  const startDate = dateStringToMoment(startDateString);
  const endDate = moment().utc(true);

  /** @type {Covid.DateSeriesItem[]} */
  const series = [];
  let date = startDate;
  while (date.unix() < endDate.unix()) {
    const dateString = momentToDateString(date);
    const dt = date.utc(true);
    if (dateString in dateGroups) {
      /** @type {Covid.Group} */
      const dateGroup = dateGroups[dateString];
      series.push({
        date: dt,
        value: dateGroup.count,
      });
    } else {
      series.push({
        date: dt,
        value: 0,
      });
    }
    date = moment(dt);
    date.add(1, 'days');
  }

  series.sort((a, b) => a.date.unix() - b.date.unix());

  return series;
}

/**
 * @param {Covid.Case[]} cases
 * @param {string} prop
 * @param {number} [numDays]  // e.g. '2020-01-12'
 * @returns {Covid.DateSeriesItem[]} cases 
 */
export function getCountDateSeriesByProp(cases, prop, numDays) {
  const dateGroups = groupBy(cases, 'announceDate');

  if (isNil(numDays)) {
    numDays = config.num_display_days;
  }
  const startDateString = momentToDateString(
    todayDate().add(-(numDays + 1), 'days'),
  );
  const startDate = dateStringToMoment(startDateString);
  const endDate = moment().utc(true);

  const propGroups = groupBy(cases, prop);
  const propNames = Object.keys(propGroups);
  const defaultNumCasesByProp = {};
  propNames.forEach(sexName => (defaultNumCasesByProp[sexName] = 0));

  /** @type {Covid.DateSeriesItem[]} */
  const series = [];
  let date = startDate;
  while (date.unix() < endDate.unix()) {
    const dateString = momentToDateString(date);
    const dt = date.utc(true);
    if (dateString in dateGroups) {
      /** @type {Covid.Group} */
      const dateGroup = dateGroups[dateString];
      /** @type {Object.<any, Covid.Group>} */
      const propGroupsByDate = groupBy(dateGroup.cases, prop);
      /** @type {Object.<string, any>} */
      const dateNumCases = {...defaultNumCasesByProp};

      for (const propValue in propGroupsByDate) {
        /** @type {Covid.Group} */
        const propGroup = propGroupsByDate[propValue];
        dateNumCases[propValue] = get(propGroup, 'cases', []).length;
      }
      series.push({
        date: dt,
        value: dateGroup.count,
        values: dateNumCases,
      });
    } else {
      series.push({
        date: dt,
        value: 0,
        values: {...defaultNumCasesByProp},
      });
    }
    date = moment(dt);
    date.add(1, 'days');
  }

  series.sort((a, b) => a.date.unix() - b.date.unix());

  return series;
}

/**
 * @param {Covid.Case[]} cases
 * @param {number} [numDays]  // e.g. '2020-01-12'
 * @returns {Covid.DateSeriesItem[]} cases 
 */
export function getCountDateSeriesBySex(cases, numDays) {
  return getCountDateSeriesByProp(cases, 'sex', numDays);
}

/**
 * @param {Covid.Case[]} cases
 * @param {number} [numDays]  // e.g. '2020-01-12'
 * @returns {Covid.DateSeriesItem[]} cases 
 */
export function getCumCountDateSeriesBySex(cases, numDays) {
  const totalDays = todayDate().diff(dateStringToMoment('2020-01-01'), 'days');
  if (isNil(numDays)) {
    numDays = config.num_display_days;
  }
  const sexGroups = groupBy(cases, 'sex');
  const sexNames = Object.keys(sexGroups);
  const defaultNumCasesBySex = {};
  sexNames.forEach(sexName => (defaultNumCasesBySex[sexName] = 0));
  /** @type {Covid.DateSeriesItem[]} */
  const countDateSeriesBySex = getCountDateSeriesBySex(cases, totalDays);

  countDateSeriesBySex.forEach(
    /**
     * @param {Covid.DateSeriesItem} item
     */
    (item, i, items) => {
      if (i > 0) {
        item.value += items[i - 1].value;
        const keys = Object.keys(item.values);
        keys.forEach(key => {
          item.values[key] += items[i - 1].values[key];
        });
      }
      let total = 0;
      for (const sex in item.values) {
        total += item.values[sex];
      }
      item.values['total'] = total;
    },
  );
  return takeRight(countDateSeriesBySex, numDays);
}

/**
 * @param {Covid.Case[]} cases
 * @returns {string[]}
 */
export function getProvinceNames(cases) {
  const provinceGroups = groupBy(cases, 'province');
  const groups = Object.values(provinceGroups).sort(
    (a, b) => b.count - a.count,
  );
  return groups.map(group => group.fieldValue);
}

/**
 * @param {Covid.Case[]} cases
 * @return {Object.<string, Covid.Province>}
 */
export function getProvinces(cases) {
  /** @type {Object.<string, Covid.Province>} */
  const provinces = {};

  const provinceGroups = groupBy(cases, 'province');

  for (const provinceName in provinceGroups) {
    /** @type {Covid.Province} */
    const province = {
      nameTh: provinceName,
      countDateSeries: [],
      countDateSeriesBySex: [],
    };

    /** @type {Covid.Group} */
    const provinceGroup = provinceGroups[provinceName];
    province.countDateSeries = getCountDateSeries(provinceGroup.cases);
    province.countDateSeriesBySex = getCountDateSeriesBySex(
      provinceGroup.cases,
    );
    province.cumCountDateSeriesBySex = getCumCountDateSeriesBySex(
      provinceGroup.cases,
    );
    province.countDateSeriesByAge = getCountDateSeriesByProp(
      provinceGroup.cases,
      'ageRange_10',
    );
    province.countDateSeriesByNation = getCountDateSeriesByProp(
      provinceGroup.cases,
      'nation',
    );
    provinces[provinceName] = province;
  }
  return provinces;
}
