import {combineReducers, createStore, applyMiddleware} from 'redux';
import {createEpicMiddleware} from 'redux-observable';
import {ormReducer} from './orm/Orm';
import epics from './epics/Epics';

function createRootReducer() {
  return combineReducers({
    orm: ormReducer
  });
}

const epicMiddlewear = createEpicMiddleware();

export const store = createStore(
  createRootReducer(),
  applyMiddleware(epicMiddlewear)
);

epicMiddlewear.run(epics);

// store.subscribe(() => {
//   console.log(`state = ${JSON.stringify(store.getState(), null, 2)}`);
// });
