

export const materialColors = {
  white: '#ffffff',
  black: '#000000',
  red_500: '#f44336',
  pink_500: '#e91e63',
  purple_500: '#9c27b0',
  deepPurple_500: '#673ab7',
  indigo_500: '#3f51b5',
  blue_500: '#2196f3',
  lightBlue_500: '#03a9f4',
  green_500: '#4caf50',
  green_800: '#2e7d32',
  lightGreen_500: '#8bc34a',
  grey_200: '#eeeeee',
  grey_500: '#9e9e9e',
};

const colors = [
  '#1976d2',
  '#00796b',
  '#f57c00',
  '#5d4037',
];

export function color(i) {
  return colors[i % colors.length];
}
