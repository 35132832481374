import React from 'react';
import PropTypes from 'prop-types';
import {
  DateSeriesBar,
  DateSeriesStackedBar,
  DateSeriesLine,
  DateSeriesMultipleLines,
} from './Charts';
import {materialColors} from '../utils/Colors';
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Label,
  Tooltip,
  Bar,
  Legend,
} from 'recharts';
import colormap from 'colormap';
import {groupBy, allAgeRanges} from '../utils/DataUtils';
import {get} from 'lodash';

export function DailyCases(props) {
  const label = 'No. of Cases';
  return (
    <DateSeriesBar
      width={props.width}
      height={props.height}
      dateSeries={props.dateSeries}
      yLabel={label}
      title={props.title}
    />
  );
}

DailyCases.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dateSeries: PropTypes.array.isRequired,
  title: PropTypes.string,
};

DailyCases.defaultProps = {
  width: 500,
  height: 500,
};

export function DailyCasesBySex(props) {
  const label = 'No. of Cases';
  return (
    <DateSeriesStackedBar
      width={props.width}
      height={props.height}
      dateSeries={props.dateSeries}
      yLabel={label}
      title={props.title}
      keys={['ชาย', 'หญิง', null]}
      keyNames={['ชาย', 'หญิง', 'unknown']}
      colors={[
        materialColors.blue_500,
        materialColors.pink_500,
        materialColors.grey_500,
      ]}
    />
  );
}

DailyCasesBySex.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dateSeries: PropTypes.array.isRequired,
  title: PropTypes.string,
};

DailyCasesBySex.defaultProps = {
  width: 500,
  height: 500,
};

export function DailyCasesByAge(props) {
  const label = 'No. of Cases';
  const ageRanges = allAgeRanges(10, 100);
  ageRanges.push(null);
  const ageTitles = allAgeRanges(10, 100);
  ageTitles.push('unknown');

  let colors = colormap({
    colormap: 'jet',
    nshades: ageRanges.length,
    format: 'hex',
    alpha: 1,
  });

  return (
    <DateSeriesStackedBar
      width={props.width}
      height={props.height}
      dateSeries={props.dateSeries}
      yLabel={label}
      title={props.title}
      keys={ageRanges}
      keyNames={ageTitles}
      colors={colors}
    />
  );
}

DailyCasesByAge.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dateSeries: PropTypes.array.isRequired,
  title: PropTypes.string,
};

DailyCasesByAge.defaultProps = {
  width: 500,
  height: 500,
};

export function DailyCasesByNation(props) {
  const label = 'No. of Cases';

  const nationGroupsDict = groupBy(props.cases, 'nation');
  const nationGroups = Object.values(nationGroupsDict).filter(
    group => group.count > 0,
  );
  nationGroups.sort((a, b) => b.count - a.count);

  const allNations = nationGroups.map(group => group.fieldValue);

  const allNationLabells = allNations.map(nation => 
    `${nation} (${nationGroupsDict[nation].count})`
  );

  let colors;
  if (allNations.length >= 6) {
    colors = colormap({
      colormap: 'jet',
      nshades: allNations.length,
      format: 'hex',
      alpha: 1,
    });  
  } else {
    colors = [
      materialColors.purple_500,
      materialColors.lightBlue_500,
      materialColors.pink_500,
      materialColors.green_500,
      materialColors.red_500,
    ];  
  }
  return (
    <DateSeriesStackedBar
      width={props.width}
      height={props.height}
      dateSeries={props.dateSeries}
      yLabel={label}
      title={props.title}
      keys={allNations}
      keyNames={allNationLabells}
      colors={colors}
    />
  );
}

DailyCasesByAge.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  cases: PropTypes.array.isRequired,
  dateSeries: PropTypes.array.isRequired,
  title: PropTypes.string,
};

DailyCasesByAge.defaultProps = {
  width: 500,
  height: 500,
};

export function DailyCumCases(props) {
  const label = 'No. of Cases';
  return (
    <DateSeriesLine
      width={props.width}
      height={props.height}
      dateSeries={props.dateSeries}
      yLabel={label}
      title={props.title}
    />
  );
}

DailyCumCases.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dateSeries: PropTypes.array.isRequired,
  title: PropTypes.string,
};

DailyCumCases.defaultProps = {
  width: 500,
  height: 500,
};

export function DailyCumCasesBySex(props) {
  const label = 'No. of Cases';
  return (
    <DateSeriesMultipleLines
      width={props.width}
      height={props.height}
      dateSeries={props.dateSeries}
      yLabel={label}
      title={props.title}
      keys={['total', 'ชาย', 'หญิง', null]}
      keyNames={['total', 'ชาย', 'หญิง', 'unknown']}
      lineWidths={[4, 1, 1, 1]}
      colors={[
        materialColors.deepPurple_500,
        materialColors.blue_500,
        materialColors.pink_500,
        materialColors.grey_500,
      ]}
    />
  );
}

DailyCumCasesBySex.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dateSeries: PropTypes.array.isRequired,
  title: PropTypes.string,
};

DailyCumCasesBySex.defaultProps = {
  width: 500,
  height: 500,
};

export function AgeDistribution(props) {
  const yearsWidth = 5;
  const ageGroups = groupBy(props.cases, `ageRange_${yearsWidth}`);
  const ranges = allAgeRanges(yearsWidth, 100);
  const data = ranges.map(range => ({
    range,
    count: get(ageGroups[range], 'count'),
  }));
  return (
    <div style={{width: props.width, height: props.height}}>
      <h3>{props.title}</h3>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          margin={{
            top: 0,
            right: 30,
            left: 20,
            bottom: 80,
          }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="range"
            label={{position: 'insideBottomRight', dy: 50}}
          />
          <YAxis type="number">
            <Label
              value="No. of Cases"
              angle={-90}
              position="insideLeft"
              style={{
                textAnchor: 'middle',
              }}
            />
          </YAxis>
          <Tooltip />
          <Bar dataKey="count" fill={materialColors.deepPurple_500} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

AgeDistribution.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  cases: PropTypes.array.isRequired,
  title: PropTypes.string,
};

AgeDistribution.defaultProps = {
  width: 500,
  height: 500,
};

export function AgeDistributionBySex(props) {
  const yearsWidth = 5;
  const ageGroups = groupBy(props.cases, `ageRange_${yearsWidth}`);
  const ranges = allAgeRanges(yearsWidth, 100);
  const allSexes = ['ชาย', 'หญิง', 'unknown'];
  const data = ranges.map(range => {
    const cases = get(ageGroups[range], 'cases');
    const sexGroups = groupBy(cases, 'sex');
    const sexCount = {};
    for (const sex in sexGroups) {
      let sexName = sex;
      if (sex === 'null') {
        sexName = 'unknown';
      }
      sexCount[sexName] = sexGroups[sex].count;
    }
    return {
      range,
      ...sexCount,
    };
  });
  const colors = {
    ชาย: materialColors.blue_500,
    หญิง: materialColors.pink_500,
    unknown: materialColors.grey_500,
  };
  return (
    <div style={{width: props.width, height: props.height}}>
      <h3>{props.title}</h3>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          margin={{
            top: 0,
            right: 30,
            left: 20,
            bottom: 80,
          }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="range"
            label={{position: 'insideBottomRight', dy: 50}}
          />
          <YAxis type="number">
            <Label
              value="No. of Cases"
              angle={-90}
              position="insideLeft"
              style={{
                textAnchor: 'middle',
              }}
            />
          </YAxis>
          <Tooltip />
          {allSexes.map((sex, i) => (
            <Bar
              key={sex}
              stackId="a"
              name={sex}
              dataKey={sex}
              fill={colors[sex]}
            />
          ))}
          <Legend verticalAlign="bottom" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

AgeDistributionBySex.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  cases: PropTypes.array.isRequired,
  title: PropTypes.string,
};

AgeDistributionBySex.defaultProps = {
  width: 500,
  height: 500,
};
