import {ofType} from 'redux-observable';
import {map, tap, mergeMap} from 'rxjs/operators';
import DailyProvinceModel from '../orm/models/DailyProvinceModel';
import {getProvices} from '../apis/ThCovid19Api';

export const loadDailyProvinceEpic = (action$, state$) =>
  action$.pipe(
    ofType(DailyProvinceModel.types.LOAD),
    mergeMap((action) => getProvices()),
    map((provinces) => DailyProvinceModel.actions.load_success(provinces))
  );
