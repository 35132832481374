import {getCases as _getCases} from './ThCovid19Api';
import {defer, of} from 'rxjs';
import {get} from 'lodash';
import { mergeMap, tap } from 'rxjs/operators';

/**
 * @param {function} apiFunction 
 * @param {string} key 
 * @param {number} maxAgeMs
 */
function wrapWithLocalStorageCache(apiFunction, key, maxAgeMs) {
  const timestampKey = `${key}_timestamp`;
  return (...params) => {
    return defer(() => {
      const now = new Date().getTime();
      const timestamp = get(localStorage, timestampKey, 0);
      const isStale = now - timestamp > maxAgeMs;
      let data;
      if (!isStale) {
        data = JSON.parse(get(localStorage, key, '{}'));
      }
      return of({isStale, data});
    }).pipe(
      mergeMap(({isStale, data}) => {
        if (!isStale) {
          return of(data);
        }
        return apiFunction(...params).pipe(
          tap(data => {
            localStorage.setItem(key, JSON.stringify(data));
            localStorage.setItem(timestampKey, `${new Date().getTime()}`);
          }),
        );
      })
    );
  };
}

/**
 * @returns {import('rxjs').Observable}
 */
export function getCases() {
  const maxAge = 600000;  // 10 minutes
  return wrapWithLocalStorageCache(_getCases, 'cases', maxAge)();
}
