import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {isNil, get} from 'lodash';
import PropTypes from 'prop-types';
import CasesModel from '../orm/models/CasesModel';
import {
  DailyCasesBySex,
  DailyCumCasesBySex,
  DailyCases,
  AgeDistribution,
  AgeDistributionBySex,
  DailyCasesByAge,
  DailyCasesByNation,
} from './SpecificCharts';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {Radio, RadioGroup, FormControlLabel, FormLabel} from '@material-ui/core';

const THAILAND = 'ประเทศไทย';

function NameSelect(props) {
  const [open, setOpen] = React.useState(false);

  const handleChange = event => {
    props.setName(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  function nameToValue(name) {
    return isNil(name) ? 'null' : name;
  }

  return (
    <FormControl>
      <Select
        style={{fontSize: 24, marginBottom: 24}}
        labelId="demo-controlled-open-select-label"
        id="demo-controlled-open-select"
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={nameToValue(props.selectedName)}
        onChange={handleChange}>
        {props.names.map((name, i) => (
          <MenuItem value={nameToValue(name)}>{props.displayNames[i]}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

NameSelect.propTypes = {
  setName: PropTypes.func.isRequired,
  selectedName: PropTypes.string.isRequired,
  names: PropTypes.arrayOf(PropTypes.string).isRequired,
  displayNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const DAILY_CASES_GROUPED_BY = {
  ALL: 'ALL',
  GENDER: 'GENDER',
  AGE: 'AGE',
  NATION: 'NATION',
};

const AGE_DISTRIBUTION_GROUPED_BY = {
  ALL: 'ALL',
  GENDER: 'GENDER',
};

export default function ProvinceDashboard(props) {
  const dispatch = useDispatch();
  const [dailyCasesGroupedBy, setDailyCasesGroupedBy] = useState(
    DAILY_CASES_GROUPED_BY.ALL,
  );
  const [ageDistributionGroupedBy, setAgeDistributionGroupedBy] = useState(
    AGE_DISTRIBUTION_GROUPED_BY.ALL
  );
  const cases = useSelector(CasesModel.selectors.cases);
  const provinceNames = useSelector(CasesModel.selectors.provinceNames);
  const provinceGroups = useSelector(CasesModel.selectors.provinceGroups);
  const provinces = useSelector(CasesModel.selectors.provinces);
  const [selectedName, setSelectedName] = useState(THAILAND);

  useEffect(() => {
    dispatch(CasesModel.actions.load());
  }, []);

  let countDateSeriesBySex = useSelector(
    CasesModel.selectors.countDateSeriesBySex,
  );
  let cumCountDateSeriesBySex = useSelector(
    CasesModel.selectors.cumCountDateSeriesBySex,
  );
  let countDateSeriesByAge = useSelector(
    CasesModel.selectors.countDateSeriesByAge,
  );
  let countDateSeriesByNation = useSelector(
    CasesModel.selectors.countDateSeriesByNation,
  );
  if (selectedName !== THAILAND) {
    countDateSeriesBySex = get(
      provinces,
      [selectedName, 'countDateSeriesBySex'],
      [],
    );
    cumCountDateSeriesBySex = get(
      provinces,
      [selectedName, 'cumCountDateSeriesBySex'],
      [],
    );
    countDateSeriesByAge = get(
      provinces,
      [selectedName, 'countDateSeriesByAge'],
      [],
    );
    countDateSeriesByNation = get(
      provinces,
      [selectedName, 'countDateSeriesByNation'],
      [],
    );
  }

  useEffect(
    () => {
      console.log(
        `countDateSeriesByAge = ${JSON.stringify(
          countDateSeriesByAge,
          null,
          2,
        )}`,
      );
    },
    [countDateSeriesByAge],
  );

  const names = [THAILAND, ...provinceNames];
  const counts = names.map(
    name =>
      name === THAILAND
        ? cases.length
        : get(provinceGroups, [name, 'count'], 0),
  );
  const displayNames = names.map(
    (name, i) =>
      isNil(name)
        ? `unknown province (${counts[i]})`
        : `${name} (${counts[i]})`,
  );

  const selectedCases =
    selectedName === THAILAND ? cases : provinceGroups[selectedName].cases;

  return (
    <div>
      <div>
        <NameSelect
          setName={name => setSelectedName(name)}
          selectedName={selectedName}
          names={names}
          displayNames={displayNames}
        />
      </div>
      <h2>Number of New Cases Per Day</h2>
      <div>
        <FormControl component="fieldset">
          <FormLabel component="legend">Grouped by</FormLabel>
          <RadioGroup
            aria-label="daily-cases" name="daily-cases"
            value={dailyCasesGroupedBy}
            row
            onChange={event => setDailyCasesGroupedBy(event.target.value)}>
            <FormControlLabel
              value={DAILY_CASES_GROUPED_BY.ALL}
              control={<Radio />}
              label="All"
            />
            <FormControlLabel
              value={DAILY_CASES_GROUPED_BY.GENDER}
              control={<Radio />}
              label="Gender"
            />
            <FormControlLabel
              value={DAILY_CASES_GROUPED_BY.AGE}
              control={<Radio />}
              label="Age"
            />
            <FormControlLabel
              value={DAILY_CASES_GROUPED_BY.NATION}
              control={<Radio />}
              label="Nation"
            />
          </RadioGroup>
        </FormControl>
        {dailyCasesGroupedBy === DAILY_CASES_GROUPED_BY.ALL && (
          <DailyCases
            width={'100%'}
            height={400}
            dateSeries={countDateSeriesBySex}
          />
        )}
        {dailyCasesGroupedBy === DAILY_CASES_GROUPED_BY.GENDER && (
          <DailyCasesBySex
            width={'100%'}
            height={400}
            dateSeries={countDateSeriesBySex}
          />
        )}
        {dailyCasesGroupedBy === DAILY_CASES_GROUPED_BY.AGE && (
          <DailyCasesByAge
            width={'100%'}
            height={400}
            dateSeries={countDateSeriesByAge}
          />
        )}
        {dailyCasesGroupedBy === DAILY_CASES_GROUPED_BY.NATION && (
          <DailyCasesByNation
            width={'100%'}
            height={400}
            cases={selectedCases}
            dateSeries={countDateSeriesByNation}
          />
        )}

        <h2>Total Cases</h2>
        <DailyCumCasesBySex
          width={'100%'}
          height={400}
          dateSeries={cumCountDateSeriesBySex}
        />

        <h2>Age Distribution</h2>
        <FormControl component="fieldset">
          <FormLabel component="legend">Grouped by</FormLabel>
          <RadioGroup
            aria-label="age-distribution" name="age-distribution"
            value={ageDistributionGroupedBy}
            row
            onChange={event => setAgeDistributionGroupedBy(event.target.value)}>
            <FormControlLabel
              value={AGE_DISTRIBUTION_GROUPED_BY.ALL}
              control={<Radio />}
              label="All"
            />
            <FormControlLabel
              value={AGE_DISTRIBUTION_GROUPED_BY.GENDER}
              control={<Radio />}
              label="Gender"
            />
          </RadioGroup>
        </FormControl>
        {ageDistributionGroupedBy === AGE_DISTRIBUTION_GROUPED_BY.ALL &&
          <AgeDistribution width={'100%'} height={400} cases={selectedCases} />
        }
        {ageDistributionGroupedBy === AGE_DISTRIBUTION_GROUPED_BY.GENDER &&
          <AgeDistributionBySex
            width={'100%'}
            height={400}
            cases={selectedCases}
          />
        }        
      </div>
    </div>
  );
}
