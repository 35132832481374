import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  Label,
} from 'recharts';
import PropTypes from 'prop-types';
import {materialColors} from '../utils/Colors';
import {isNil} from 'lodash';

export function DateSeriesLine(props) {
  const data = props.dateSeries.map(
    /**
     * @param {Covid.DateSeriesItem} dateSeriesItem
     */
    (dateSeriesItem) => ({
      date: `${dateSeriesItem.date.format('D MMM')}`,
      value: dateSeriesItem.value,
    }),
  );

  return (
    <div style={{width: props.width, height: props.height}}>
      <h3>{props.title}</h3>
      <ResponsiveContainer width={'100%'} height={'100%'}>
        <LineChart
          data={data}
          margin={{
            top: 0,
            right: 30,
            left: 20,
            bottom: 80,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            angle={0}
            label={{position: 'insideBottomRight', dy: 50}}
          />
          <YAxis type="number">
            <Label
              value={props.yLabel}
              angle={-90}
              position="insideLeft"
              style={{
                textAnchor: 'middle',
              }}
            />
          </YAxis>
          <Tooltip />
          <Line
            strokeWidth={2}
            type="monotone"
            dataKey="value"
            stroke="#8884d8"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

DateSeriesLine.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dateSeries: PropTypes.array.isRequired,
  yLabel: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.string,
};

DateSeriesLine.defaultProps = {
  width: 500,
  height: 500,
  yLabel: '',
  title: '',
  color: materialColors.indigo_500,
};

export function DateSeriesMultipleLines(props) {
  const data = props.dateSeries.map(
    /**
     * @param {Covid.DateSeriesItem} dateSeriesItem
     */
    (dateSeriesItem) => ({
      date: `${dateSeriesItem.date.format('D MMM')}`,
      ...dateSeriesItem.values,
    }),
  );
  const keys = props.keys.map((key) => `${key}`);
  if (isNil(props.lineWidths)) {
    props.lineWidths = keys.map(key => 2);
  }
  return (
    <div style={{width: props.width, height: props.height}}>
      <h3>{props.title}</h3>
      <ResponsiveContainer width={'100%'} height={'100%'}>
        <LineChart
          data={data}
          margin={{
            top: 0,
            right: 30,
            left: 20,
            bottom: 80,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            angle={0}
            label={{position: 'insideBottomRight', dy: 50}}
          />
          <YAxis type="number">
            <Label
              value={props.yLabel}
              angle={-90}
              position="insideLeft"
              style={{
                textAnchor: 'middle',
              }}
            />
          </YAxis>
          <Tooltip />
          {keys.map((key, i) => (
            <Line
              key={key}
              strokeWidth={props.lineWidths[i]}
              type="monotone"
              name={props.keyNames[i]}
              dataKey={key}
              stroke={props.colors[i]}
            />
          ))}
          <Legend verticalAlign="bottom" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

DateSeriesMultipleLines.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dateSeries: PropTypes.array.isRequired,
  yLabel: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.string,
  keys: PropTypes.arrayOf(PropTypes.string).isRequired,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  keyNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  lineWidths: PropTypes.arrayOf(PropTypes.number),
};

DateSeriesMultipleLines.defaultProps = {
  width: 500,
  height: 500,
  yLabel: '',
  title: '',
};

export function DateSeriesBar(props) {
  const data = props.dateSeries.map(
    /**
     * @param {Covid.DateSeriesItem} dateSeriesItem
     */
    (dateSeriesItem) => ({
      dateString: `${dateSeriesItem.date.format('D MMM')}`,
      value: dateSeriesItem.value,
    }),
  );
  return (
    <div style={{width: props.width, height: props.height}}>
      <h3>{props.title}</h3>
      <ResponsiveContainer width={'100%'} height={'100%'}>
        <BarChart
          data={data}
          margin={{
            top: 0,
            right: 30,
            left: 20,
            bottom: 80,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="dateString"
            angle={0}
            domain={['dataMin', 'dataMax']}
            label={{position: 'insideBottomRight', dy: 50}}
          />
          <YAxis type="number">
            <Label
              value={props.yLabel}
              angle={-90}
              position="insideLeft"
              style={{
                textAnchor: 'middle',
              }}
            />
          </YAxis>
          <Tooltip />
          <Bar dataKey="value" fill={materialColors.deepPurple_500} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

DateSeriesBar.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dateSeries: PropTypes.array.isRequired,
  yLabel: PropTypes.string,
  title: PropTypes.string,
};

DateSeriesBar.defaultProps = {
  width: 500,
  height: 500,
  yLabel: '',
  title: '',
};

export function DateSeriesStackedBar(props) {
  const data = props.dateSeries.map(
    /**
     * @param {Covid.DateSeriesItem} dateSeriesItem
     */
    (dateSeriesItem) => ({
      dateString: `${dateSeriesItem.date.format('D MMM')}`,
      date: dateSeriesItem.date,
      ...dateSeriesItem.values,
    }),
  );
  const keys = props.keys.map((key) => `${key}`);
  function onBarClicked(e) {
    // console.log(`click ${JSON.stringify(e, null, 2)}`);
  }
  return (
    <div style={{width: props.width, height: props.height}}>
      <h3>{props.title}</h3>
      <ResponsiveContainer width={'100%'} height={'100%'}>
        <BarChart
          data={data}
          margin={{
            top: 0,
            right: 30,
            left: 20,
            bottom: 80,
          }}
          onClick={onBarClicked}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="dateString"
            angle={0}
            domain={['dataMin', 'dataMax']}
            label={{position: 'insideBottomRight', dy: 50}}
          />
          <YAxis type="number">
            <Label
              value={props.yLabel}
              angle={-90}
              position="insideLeft"
              style={{
                textAnchor: 'middle',
              }}
            />
          </YAxis>
          <Tooltip />
          {keys.map((key, i) => (
            <Bar
              key={key}
              stackId="a"
              name={props.keyNames[i]}
              dataKey={key}
              fill={props.colors[i % props.colors.length]}
            />
          ))}
          <Legend verticalAlign="bottom" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

DateSeriesStackedBar.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dateSeries: PropTypes.array.isRequired,
  yLabel: PropTypes.string,
  title: PropTypes.string,
  keys: PropTypes.arrayOf(PropTypes.string).isRequired,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  keyNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

DateSeriesStackedBar.defaultProps = {
  width: 500,
  height: 500,
  yLabel: '',
  title: '',
};
