import BaseModel from '../BaseModel';
import {attr, createSelector} from 'redux-orm';
import {get} from 'lodash';
import '../../Typedef';
import {
  getProvinces,
  getProvinceNames,
  getCountDateSeries,
  getCountDateSeriesBySex,
  getCumCountDateSeriesBySex,
  groupBy,
  ageToAgeRange,
  getCountDateSeriesByProp,
} from '../../utils/DataUtils';

export default class CasesModel extends BaseModel {
  static types = {
    LOAD: 'cases/load',
    LOAD_SUCCESS: 'cases/load_success',
    LOAD_FAILURE: 'cases/load_failure',
  };

  static actions = {
    load: () => ({
      type: CasesModel.types.LOAD,
    }),
    loadSuccess: cases => ({
      type: CasesModel.types.LOAD_SUCCESS,
      payload: {
        cases,
      },
    }),
    loadFailure: () => ({
      type: CasesModel.types.LOAD_SUCCESS,
    }),
  };

  /**
   * @type {{
   *   cases: function(): Covid.Case[],
   *   provinceNames: function(): string[],
   *   provinces: function(): Object.<string, Covid.Province>,
   *   provinceGroups: function(): Object.<string, Covid.Group>,
   *   countDateSeries: function(): Covid.DateSeriesItem[],
   *   countDateSeriesBySex: function(): Covid.DateSeriesItem[],
   *   cumCountDateSeriesBySex: function(): Covid.DateSeriesItem[],
   *   countDateSeriesByAge: function(): Covid.DateSeriesItem[],
   *   countDateSeriesByNation: function(): Covid.DateSeriesItem[],
   * }}
   */
  static selectors;

  static initSelectors(orm) {
    this.selectors = {
      cases: createSelector(orm, session =>
        session.CasesModel.all().toRefArray(),
      ),
      provinceNames: createSelector(orm, session =>
        getProvinceNames(session.CasesModel.all().toRefArray()),
      ),
      provinces: createSelector(orm, session =>
        getProvinces(session.CasesModel.all().toRefArray()),
      ),
      provinceGroups: createSelector(orm, session =>
        groupBy(session.CasesModel.all().toRefArray(), 'province'),
      ),
      countDateSeries: createSelector(orm, session =>
        getCountDateSeries(session.CasesModel.all().toRefArray()),
      ),
      countDateSeriesBySex: createSelector(orm, session =>
        getCountDateSeriesBySex(session.CasesModel.all().toRefArray()),
      ),
      cumCountDateSeriesBySex: createSelector(orm, session =>
        getCumCountDateSeriesBySex(session.CasesModel.all().toRefArray()),
      ),
      countDateSeriesByAge: createSelector(orm, session =>
        getCountDateSeriesByProp(
          session.CasesModel.all().toRefArray(),
          'ageRange_10',
        ),
      ),
      countDateSeriesByNation: createSelector(orm, session =>
        getCountDateSeriesByProp(
          session.CasesModel.all().toRefArray(),
          'nation',
        ),
      )
    };
  }

  static reducer(action, CasesModel, session) {
    switch (action.type) {
      case this.types.LOAD:
        break;

      case this.types.LOAD_SUCCESS: {
        /** @type {Covid.Api.Case[]} */
        const cases = action.payload.cases;
        cases.map((cs, i) =>
          this.upsert({
            id: i + 1,
            no: cs.no,
            province: cs.province,
            age: cs.age,
            ageRange_5: ageToAgeRange(cs.age, 5),
            ageRange_10: ageToAgeRange(cs.age, 10),
            nation: cs.nation,
            occupation: cs.occupation,
            sex: cs.sex,
            date: cs.date,
            district: cs.district,
            risk: cs.risk,
            announceDate: cs.announce_date,
            notificationDate: cs.notification_date,
          }),
        );
        break;
      }
      case this.types.LOAD_FAILURE:
        break;

      default:
        break;
    }
  }
}

CasesModel.modelName = 'CasesModel';

CasesModel.fields = {
  id: attr(),
  no: attr(),
  province: attr(),
  age: attr(),
  ageRange_5: attr(),
  ageRange_10: attr(),
  nation: attr(),
  occupation: attr(),
  sex: attr(),
  date: attr(),
  district: attr(),
  risk: attr(),
  announceDate: attr(),
  notificationDate: attr(),
};
