import React, {useState} from 'react';
import logo from './logo.svg';
import './App.css';
import {store} from './InitRedux';
import {Provider} from 'react-redux';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import ProvinceDashboard from './components/ProvinceDashboard';
import {materialColors} from './utils/Colors';

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <h1>Thailand COVID-19 Cases by Province</h1>
        <ProvinceDashboard />
        <Typography style={{color: materialColors.grey_500, textAlign: 'left'}}>
          <Typography component="div">
            Data sources:&nbsp;
            <br />
            <Link
              href="https://covid19.th-stat.com/en/api"
              target="_blank">
              Open API by Department of Disease Control
            </Link>
            <br />
            <Link
              href="https://data.go.th/en/dataset/covid-19-daily"
              target="_blank">
              COVID-19 Daily Report by Open Government Data of Thailand
            </Link>
          </Typography>
          <Typography component="div">
            Developer contact:&nbsp;
            <Link
              style={{color: materialColors.grey_500}}
              href={`mailto:${atob('cGN2LmRldkBnbWFpbC5jb20=')}`}>
              {atob('cGN2LmRldkBnbWFpbC5jb20=')}
            </Link>
          </Typography>
        </Typography>
      </div>
    </Provider>
  );
}

export default App;
