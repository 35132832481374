import {Model} from 'redux-orm';
import PropTypes from 'prop-types';

/**this
 * From https://gist.github.com/tommikaikkonen/45d0d2ff2a5a383bb14d
 */
export default class BaseModel extends Model {
  // static dbSelector = state => state.db;

  static _validateProps(props) {
    if (typeof this.propTypes === 'object') {
      PropTypes.checkPropTypes(this.propTypes, props, 'prop', 'MyModel');
    }
  }

  static create(props) {
    const defaults = this.hasOwnProperty('defaultProps')
      ? this.defaultProps
      : {};
    const propsWithDefaults = Object.assign({}, defaults, props);
    this._validateProps(propsWithDefaults);
    return super.create(propsWithDefaults);
  }

  /**
   * Update the only one object for this table.
   * @param {*} obj 
   */
  static updateTheOne(obj) {
    this.upsert({id: 0, ...obj});
  }

  /**
   * Get the only one object for this table.
   */
  static theOne() {
    return this.withId(0);
  }

  static deleteTheOne() {
    const theOne = this.withId(0);
    if (theOne) {
      theOne.delete();
    }
  }
}
