import {ofType} from 'redux-observable';
import {map, mergeMap} from 'rxjs/operators';
import CasesModel from '../orm/models/CasesModel';
import {getCases} from '../apis/ThCovid19CacheApi';

export const casesEpic = (action$, state$) => action$.pipe(
  ofType(CasesModel.types.LOAD),
  mergeMap(action => getCases()),
  map(cases => CasesModel.actions.loadSuccess(cases)),
);
