import BaseModel from '../BaseModel';
import {attr, createSelector} from 'redux-orm';
import {get} from 'lodash';
import '../../Typedef';

export default class DailyProvinceModel extends BaseModel {
  static types = {
    LOAD: 'daily-province/load',
    LOAD_SUCCESS: 'daily-province/load_success',
    LOAD_FAILURE: 'daily-province/load_failure'
  };

  static actions = {
    load: () => ({
      type: DailyProvinceModel.types.LOAD
    }),
    load_success: (provinces) => ({
      type: DailyProvinceModel.types.LOAD_SUCCESS,
      payload: {
        provinces
      }
    }),
    load_failure: () => ({
      type: DailyProvinceModel.types.LOAD_SUCCESS
    })
  };

  static selectors;

  static initSelectors(orm) {
    this.selectors = {
      province: createSelector(
        orm,
        (_, provinceName) => provinceName,
        (session, provinceName) =>
          get(session.DailyProvinceModel.withId(provinceName), 'ref')
      )
    };
  }

  static reducer(action, DailyProvinceModel, session) {
    switch (action.type) {
      case this.types.LOAD:
        break;
      case this.types.LOAD_SUCCESS:
        /** @type {Covid.Api.Province[]} */
        const provinces = action.payload.provinces;

        provinces.map((province) => {
          this.upsert({
            id: province.province,
            count: province.count,
            timeline: province.timeline
          });
        });

        break;
      case this.types.LOAD_FAILURE:
        break;
      default:
        break;
    }
  }
}

DailyProvinceModel.modelName = 'DailyProvinceModel';

DailyProvinceModel.fields = {
  id: attr(), // name in Thai
  count: attr(),
  timeline: attr()
};
