import { ORM, createReducer } from 'redux-orm';
import DailyProvinceModel from './models/DailyProvinceModel';
import CasesModel from './models/CasesModel';

const orm = new ORM({stateSelector: state => state.orm});

orm.register(
  DailyProvinceModel,
  CasesModel,
);

DailyProvinceModel.initSelectors(orm);
CasesModel.initSelectors(orm);

export default orm;
export const ormReducer = createReducer(orm);
